import React from 'react'
import { FaFacebookF, FaTwitter, FaLinkedinIn } from "react-icons/fa";
import { logo } from "../../assets/index"
import { Link } from "react-scroll";


const Footer = () => {
  return (
    <div className="w-full py-20 h-auto border-b-[1px] border-b-black grid grid-cols-1 md:grid-cols-2 lgl:grid-cols-4 gap-8">
      <div className="w-full h-full flex flex-col gap-8">
        <img className="w-32" src={logo} alt="logo" />
        <div className="flex gap-4">
          <span className="bannerIcon">

            <a href="https://www.facebook.com/people/Entsoft/61570439594308/" target="_blank" rel="noopener noreferrer">
              <FaFacebookF />
            </a>
          </span>
          <span className="bannerIcon">


            <a href="https://x.com/EntsoftOfficial" target="_blank" rel="noopener noreferrer">
              <FaTwitter />
            </a>
          </span>
          <span className="bannerIcon">

            <a href="https://www.instagram.com/entsoftofficial" target="_blank" rel="noopener noreferrer">
              <FaLinkedinIn />
            </a>
          </span>
        </div>
      </div>
      <div className="w-full h-full">
        <h3 className="text-xl uppercase text-designColor tracking-wider">
          Quick Link
        </h3>
        <ul className="flex flex-col gap-4 font-titleFont font-medium py-6 overflow-hidden">
          <li>
            <span className="w-full text-lg relative hover:text-designColor duration-300 group cursor-pointer">

              <span key={1001}>
                <Link
                  activeClass="active"
                  to={'home'}
                  spy={true}
                  smooth={true}
                  duration={500}
                  offset={-96}
                  style={{ color: 'rgb(194, 200, 211)', textDecoration: 'none' }}
                >
                  {'Home'}
                </Link>
              </span>
              <span className="w-full h-[1px] bg-designColor inline-flex absolute left-0 -bottom-1 -translate-x-[100%] group-hover:translate-x-0 transition-transform duration-300"></span>
            </span>
          </li>

          <li>
            <span className="w-full text-lg relative hover:text-designColor duration-300 group cursor-pointer">

              <span key={1002}>
                <Link
                  activeClass="active"
                  to={'services'}
                  spy={true}
                  smooth={true}
                  duration={500}
                  offset={-96}
                  style={{ color: 'rgb(194, 200, 211)', textDecoration: 'none' }}
                >
                  {'Services'}
                </Link>
              </span>
              <span className="w-full h-[1px] bg-designColor inline-flex absolute left-0 -bottom-1 -translate-x-[100%] group-hover:translate-x-0 transition-transform duration-300"></span>
            </span>
          </li>

          <li>
            <span className="w-full text-lg relative hover:text-designColor duration-300 group cursor-pointer">
              <span key={1003}>
                <Link
                  activeClass="active"
                  to={'projects'}
                  spy={true}
                  smooth={true}
                  duration={500}
                  offset={-96}
                  style={{ color: 'rgb(194, 200, 211)', textDecoration: 'none' }}
                >
                  {'Projects'}
                </Link>
              </span>
              <span className="w-full h-[1px] bg-designColor inline-flex absolute left-0 -bottom-1 -translate-x-[100%] group-hover:translate-x-0 transition-transform duration-300"></span>
            </span>
          </li>

          <li>
            <span className="w-full text-lg relative hover:text-designColor duration-300 group cursor-pointer">
              <span key={1004}>
                <Link
                  activeClass="active"
                  to={'testimonial'}
                  spy={true}
                  smooth={true}
                  duration={500}
                  offset={-96}
                  style={{ color: 'rgb(194, 200, 211)', textDecoration: 'none' }}
                >
                  {'Testimonial'}
                </Link>
              </span>
              <span className="w-full h-[1px] bg-designColor inline-flex absolute left-0 -bottom-1 -translate-x-[100%] group-hover:translate-x-0 transition-transform duration-300"></span>
            </span>
          </li>

        </ul>
      </div>



      <div className="w-full h-full">
        <h3 className="text-xl uppercase text-designColor tracking-wider">
          Address
        </h3>
        <ul className="flex flex-col gap-4 font-titleFont font-medium py-6 overflow-hidden">
          <li>
            <span className="w-full text-lg relative hover:text-designColor duration-300 group cursor-pointer">
              <a href="https://www.google.com/maps?q=shop+G08,+SIJ+Mall+Broad+Street,+Marina,+Lagos,+Nigeria" target="_blank" rel="noopener noreferrer">
                Shop G08,
              </a>
              <span className="w-full h-[1px] bg-designColor inline-flex absolute left-0 -bottom-1 -translate-x-[100%] group-hover:translate-x-0 transition-transform duration-300"></span>
            </span>
          </li>
          <li>
            <span className="w-full text-lg relative hover:text-designColor duration-300 group cursor-pointer">

              <a href="https://www.google.com/maps?q=shop+G08,+SIJ+Mall+Broad+Street,+Marina,+Lagos,+Nigeria" target="_blank" rel="noopener noreferrer">
                SIJ Mall Broad Street,
              </a>
              <span className="w-full h-[1px] bg-designColor inline-flex absolute left-0 -bottom-1 -translate-x-[100%] group-hover:translate-x-0 transition-transform duration-300"></span>
            </span>
          </li>
          <li>
            <span className="w-full text-lg relative hover:text-designColor duration-300 group cursor-pointer">

              <a href="https://www.google.com/maps?q=shop+G08,+SIJ+Mall+Broad+Street,+Marina,+Lagos,+Nigeria" target="_blank" rel="noopener noreferrer">
                Marina,
              </a>

              <span className="w-full h-[1px] bg-designColor inline-flex absolute left-0 -bottom-1 -translate-x-[100%] group-hover:translate-x-0 transition-transform duration-300"></span>
            </span>
          </li>
          <li>
            <span className="w-full text-lg relative hover:text-designColor duration-300 group cursor-pointer">
              <a href="https://www.google.com/maps?q=shop+G08,+SIJ+Mall+Broad+Street,+Marina,+Lagos,+Nigeria" target="_blank" rel="noopener noreferrer">
                Lagos, Nigeria.
              </a>

              <span className="w-full h-[1px] bg-designColor inline-flex absolute left-0 -bottom-1 -translate-x-[100%] group-hover:translate-x-0 transition-transform duration-300"></span>
            </span>
          </li>

        </ul>
      </div>


      <div className="w-full h-full">
        <h3 className="text-xl uppercase text-designColor tracking-wider">
          Support Link
        </h3>
        <ul className="flex flex-col gap-4 font-titleFont font-medium overflow-hidden py-6">


          <li>
            <span className="w-full text-lg relative hover:text-designColor duration-300 group cursor-pointer">

              <span key={1005}>
                <Link
                  activeClass="active"
                  to={'contact'}
                  spy={true}
                  smooth={true}
                  duration={500}
                  style={{ color: 'rgb(194, 200, 211)', textDecoration: 'none' }}
                >
                  {'Contact'}
                </Link>
              </span>

              <span className="w-full h-[1px] bg-designColor inline-flex absolute left-0 -bottom-1 -translate-x-[100%] group-hover:translate-x-0 transition-transform duration-300"></span>
            </span>
          </li>


          <li>
            <span className="w-full text-lg relative hover:text-designColor duration-300 group cursor-pointer">
              <a href="https://wa.me/+2348039456059" target="_blank" rel="noopener noreferrer">
                Whatsapp
              </a>

              <span className="w-full h-[1px] bg-designColor inline-flex absolute left-0 -bottom-1 -translate-x-[100%] group-hover:translate-x-0 transition-transform duration-300"></span>
            </span>
          </li>
          <li>
            <span className="w-full text-lg relative hover:text-designColor duration-300 group cursor-pointer">
              <a href="mailto:support@entsoft.com.ng" target="_blank" rel="noopener noreferrer">
                support@entsoft.com.ng
              </a>
              <span className="w-full h-[1px] bg-designColor inline-flex absolute left-0 -bottom-1 -translate-x-[100%] group-hover:translate-x-0 transition-transform duration-300"></span>
            </span>
          </li>
          <li>
            <span className="w-full text-lg relative hover:text-designColor duration-300 group cursor-pointer">

              <a href="tel:+2348039456059" target="_blank" rel="noopener noreferrer">
                +234 803 945 6059
              </a>
              <span className="w-full h-[1px] bg-designColor inline-flex absolute left-0 -bottom-1 -translate-x-[100%] group-hover:translate-x-0 transition-transform duration-300"></span>
            </span>
          </li>

          {/* <li>
            <span className="w-full text-lg relative hover:text-designColor duration-300 group cursor-pointer">
              Open Source
              <span className="w-full h-[1px] bg-designColor inline-flex absolute left-0 -bottom-1 -translate-x-[100%] group-hover:translate-x-0 transition-transform duration-300"></span>
            </span>
          </li> */}

        </ul>
      </div>

    </div>
  );
}

export default Footer