// import React from 'react'
// import { useTypewriter, Cursor } from "react-simple-typewriter";
// import Media from './Media';

// const LeftBanner = () => {
//     const [text] = useTypewriter({
//       words: ["Software Engineering.", "Network Engineering.", "CyberSecurity.", "Cloud Computing.", "Mobile development." , "Internet services."],
//       loop: true,
//       typeSpeed: 200,
//       deleteSpeed: 10,
//       delaySpeed: 2000,
//     });
//   return (
//     <div className="w-full lgl:w-1/2 flex flex-col gap-8">
//       <div className="flex flex-col gap-5">
//         <h4 className=" text-lg font-normal">HELLO!</h4>
//         <h1 className="text-6xl font-bold text-white">
//           We are <span className="text-designColor capitalize">Expert</span>
//         </h1>
//         <h2 className="text-4xl font-bold text-white">
//           in <span>{text}</span>
//           <Cursor
//             cursorBlinking="false"
//             cursorStyle="|"
//             cursorColor="#ffbd39"
//           />
//         </h2>
//         <p className="text-base font-bodyFont leading-6 tracking-wide">
//           Our team brings your ideas to life by combining expertise and years of experience across diverse fields, ensuring your business receives comprehensive, secure, and effective support at every stage. From design and development to seamless deployment, proactive management, security enhancements, and targeted digital marketing—trust us, we’ve got you covered.
//         </p>
//       </div>
//      <Media />
//     </div>
//   );
// }

// export default LeftBanner





// import React from 'react';
// import { useTypewriter, Cursor } from "react-simple-typewriter";
// import Media from './Media';

// const LeftBanner = () => {
//     const [text] = useTypewriter({
//       words: ["Software Engineering.", "Network Engineering.", "CyberSecurity.", "Cloud Computing.", "Mobile Development.", "Internet Services."],
//       loop: true,
//       typeSpeed: 200,
//       deleteSpeed: 10,
//       delaySpeed: 2000,
//     });

//   return (
//     <div className="w-full lgl:w-1/2 flex flex-col gap-8">
//       {/* <div className="flex flex-col gap-5 bg-gray-900 p-6 rounded-lg shadow-lg"> */}
//       <div className="flex flex-col gap-5  p-6 rounded-lg shadow-lg">
//         <h4 className="text-lg font-normal text-gray-300">HELLO!</h4>
//         <h1 className="text-6xl font-bold text-white">
//           We are <span className="text-designColor capitalize">Expert</span>
//         </h1>
//         <div 
//           className="relative w-auto bg-black text-white rounded-md px-4 flex items-center overflow-hidden"
//           style={{
//             height: "120px", // Fixed height
//             fontFamily: '"Permanent Marker", cursive', // Chalk-like font
//             // border: "2px dashed #ccc", // Blackboard style border
//             border: "2px dashed #ccc", // Blackboard style border
//             whiteSpace: "normal", // Allow text to wrap
//             wordWrap: "break-word", // Break long words
//           }}
//         >
//           <h2 className="text-4xl font-bold text-white w-full overflow-wrap">
//             in <span>{text}</span>
//             <Cursor
//               cursorBlinking={false}
//               cursorStyle="|"
//               cursorColor="#ffffff"
//             />
//           </h2>
//         </div>
//         <p className="text-base font-bodyFont leading-6 tracking-wide text-gray-300 mt-4">
//           Our team brings your ideas to life by combining expertise and years of experience across diverse fields, ensuring your business receives comprehensive, secure, and effective support at every stage. From design and development to seamless deployment, proactive management, security enhancements, and targeted digital marketing—trust us, we’ve got you covered.
//         </p>
//       </div>
//       <Media />
//     </div>
//   );
// };

// export default LeftBanner;


import React from 'react';
import { useTypewriter, Cursor } from "react-simple-typewriter";
import Media from './Media';
import { useMediaQuery } from 'react-responsive';

const LeftBanner = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 }); // Adjust width as needed for mobile detection
  const [text] = useTypewriter({
    words: [
      "Software Engineering.",
      "Network Engineering.",
      "CyberSecurity.",
      "Cloud Computing.",
      "Mobile Development.",
      "Internet Services.",
    ],
    loop: true,
    typeSpeed: 200,
    deleteSpeed: 10,
    delaySpeed: 2000,
  });

  return (
    <div className="w-full lgl:w-1/2 flex flex-col gap-8">
      <div className="flex flex-col gap-5">
        <h4 className="text-lg font-normal text-gray-300">HELLO!</h4>
        <h1 className="text-6xl font-bold text-white">
          We are <span className="text-designColor capitalize">Expert</span>
        </h1>

        {isMobile ? (
          // Blackboard style for mobile
          <div
            className="relative w-auto bg-black text-white rounded-md px-4 flex items-center overflow-hidden"


            style={{
              height: "70px", // Fixed height
              // fontFamily: '"Permanent Marker", cursive', // Chalk-like font
              fontFamily: 'Poppins, sans-serif', // Correct syntax


              fontStyle: "normal", // Prevent italic style
              border: "2px dashed #ccc", // Blackboard style border
              whiteSpace: "normal", // Allow text to wrap
              wordWrap: "break-word", // Break long words
            }}
          >
            <h2 className="text-2xl font-bold text-white w-full">
              in <span>{text}</span>
              <Cursor
                cursorBlinking={false}
                cursorStyle="|"
                cursorColor="#ffffff"
              />
            </h2>
          </div>




          // <div
          //   className="relative w-auto bg-black text-white rounded-md px-4 flex items-center overflow-hidden"
          //   style={{
          //     height: "120px", // Fixed height
          //     fontFamily: '"Permanent Marker", cursive', // Chalk-like font
          //     // border: "2px dashed #ccc", // Blackboard style border
          //     border: "2px dashed #ccc", // Blackboard style border
          //     whiteSpace: "normal", // Allow text to wrap
          //     wordWrap: "break-word", // Break long words
          //   }}
          // >
          //   <h2 className="text-4xl font-bold text-white w-full overflow-wrap">
          //     in <span>{text}</span>
          //     <Cursor
          //       cursorBlinking={false}
          //       cursorStyle="|"
          //       cursorColor="#ffffff"
          //     />
          //   </h2>
          // </div>



        ) : (
          // Regular style for desktop/laptop
          <h2 className="text-4xl font-bold text-white">
            in <span>{text}</span>
            <Cursor
              cursorBlinking={false}
              cursorStyle="|"
              cursorColor="#ffbd39"
            />
          </h2>
        )}

        <p className="text-base font-bodyFont leading-6 tracking-wide text-gray-300 mt-4">
          Our team brings your ideas to life by combining expertise and years of experience across diverse fields, ensuring your business receives comprehensive, secure, and effective support at every stage. From design and development to seamless deployment, proactive management, security enhancements, and targeted digital marketing—trust us, we’ve got you covered.
        </p>
      </div>
      <Media />
    </div>
  );
};

export default LeftBanner;
