
import React from 'react';
import Title from '../layouts/Title';
import { projectecommerce, projectsolar, projectoutlook, } from "../../assets/index";
import { ProjectsCard2, ProjectsCard3 } from './ProjectsCard';

const Projects = () => {
  return (
    <section
      id="projects"
      className="w-full py-20 border-b-[1px] border-b-black"
    >
      <div className="flex justify-center items-center text-center">
        <Title
          title="HIGHLIGHT OF SOME OF OUR WORK"
          des="Projects"
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 xl:gap-14">


        <ProjectsCard2
          title="E-Commerce Platform with Advanced Security"
          des="Built for a leading retail client, this robust e-commerce platform features encrypted transactions, data protection, and compliance-driven security measures for a safe and seamless user experience."
          src={projectecommerce}
          link="https://westgatelifecare.com.ng"
        />

        <ProjectsCard3
          title="Professional Enterprise Email System Integration"
          des="We implemented a secure enterprise email solution for over 200 users, featuring anti-spam protection, mobile compatibility, and data retention to enhance professional communication."
          src={projectoutlook}
          link="https://www.youtube.com/@Entsoft_com_ng"
        />

        <ProjectsCard3
          title="Custom Solar Quotation Calculator"
          des="We developed a solar calculator app for non-technical users e.g marketers, allowing marketers to input appliances and calculate the necessary solar requirements and quotations. "
          src={projectsolar}
          link="https://www.youtube.com/@Entsoft_com_ng"
        />



      </div>
    </section>
  )
}

export default Projects;
