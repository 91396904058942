

import { AiFillAppstore } from "react-icons/ai"; 
import { FaMobile, FaGlobe, FaShieldAlt, FaEnvelope, FaCamera } from "react-icons/fa"; 
import { SiProgress, SiAntdesign, SiCloudflare } from "react-icons/si";
import { MdOutlineEngineering, MdOutlineBusinessCenter } from "react-icons/md";
import { BiBullseye } from "react-icons/bi"; // Icon for Digital Marketing

// Features Data
export const featuresData = [
  {
    id: 1,
    icon: <MdOutlineBusinessCenter />,
    title: "Strategic Solutions",
    des: "We transform complex ideas into actionable solutions that align with your business goals, leveraging technology to optimize and grow.",
  },
  {
    id: 2,
    icon: <MdOutlineEngineering />,
    title: "Software Engineering",
    des: "Our team builds high-performance software tailored to your unique needs, ensuring efficiency, scalability, and a competitive edge.",
  },
  {
    id: 4,
    icon: <FaEnvelope />,
    title: "Email Solutions",
    des: "We offer professional enterprise email setups with unlimited users, all at an affordable yearly rate tailored to meet your business needs.",
  },
  {
    id: 9,
    icon: <SiAntdesign />,
    title: "POS Application",
    des: "Get efficient user-friendly Point of Sale applications tailored for your buisness, enabling seamless transactions and inventory management.",
  },
  {
    id: 6,
    icon: <SiCloudflare />,
    title: "Cloud Architecture",
    des: "Our certified AWS, Google, and Oracle cloud architects design and implement scalable, secure cloud architectures.",
  },  
  {
    id: 7,
    icon: <SiProgress />,
    title: "Network Engineering",
    des: "Our Cisco-certified engineers bring over a decade of hands-on experience in deploying robust LAN and WAN connectivity.",
  },
  
  {
    id: 10,
    icon: <FaGlobe />,
    title: "Internet Services",
    des: "Our comprehensive internet services cover everything from hosting and domain management to high-speed, secure connectivity solutions.",
  }, {
    id: 12,
    icon: <FaShieldAlt />,
    title: "Cybersecurity",
    des: "We safeguard your operations with comprehensive cybersecurity, from threat detection and data protection to compliance and recovery solutions.",
  },
  
  {
    id: 11,
    icon: <FaCamera />, // You can change this icon to another if needed, such as a CCTV icon.
    title: "CCTV Solutions",
    des: "We offer cutting-edge CCTV solutions to monitor and protect your premises, ensuring security and peace of mind with advanced surveillance technology.",
  },
  {
    id: 5,
    icon: <AiFillAppstore />,
    title: "Web Development",
    des: "We create intuitive, high-impact applications that enhance user experiences and foster engagement across all digital platforms.",
  },  
  {
    id: 8,
    icon: <FaMobile />,
    title: "Mobile Development",
    des: "Reach your audience wherever they are with mobile applications crafted for smooth performance and memorable user interactions.",
  },

  {
    id: 3,
    icon: <BiBullseye />,
    title: "Digital Marketing",
    des: "Our digital marketing strategies are crafted to increase your reach, build brand authority, and drive conversions across digital platforms.",
  },
 
];
