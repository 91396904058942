
import React from 'react';
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaInstagram } from "react-icons/fa";
import { FaWhatsapp, FaPhone, FaEnvelope } from "react-icons/fa";

const Media = () => {
  return (
    <div className="flex flex-col xl:flex-row gap-6 lgl:gap-0 justify-between">
      <div>
        <h2 className="text-base uppercase font-titleFont mb-4">
          Reach Us Now
        </h2>
        <div className="flex gap-4">
          <span className="bannerIcon ctaIcon">
            <a href="https://wa.me/+2348039456059" target="_blank" rel="noopener noreferrer">
              <FaWhatsapp style={{ color: '#ffbd39' }}/>
            </a>
          </span>
          <span className="bannerIcon ctaIcon">
            <a href="tel:+2348039456059" target="_blank" rel="noopener noreferrer">
              <FaPhone style={{ color: '#ffbd39' }}/>
            </a>
          </span>
          <span className="bannerIcon ctaIcon">
            <a href="mailto:Info@entsoft.com.ng" target="_blank" rel="noopener noreferrer">
              <FaEnvelope style={{ color: '#ffbd39' }}/>
            </a>
          </span>
        </div>
      </div>
      <div>
        <h2 className="text-base uppercase font-titleFont mb-4">
          Join Our Community
        </h2>
        <div className="flex gap-4">
          <span className="bannerIcon">
            <a href="https://www.facebook.com/people/Entsoft/61570439594308/" target="_blank" rel="noopener noreferrer">
              <FaFacebookF />
            </a>
          </span>
          <span className="bannerIcon">
            <a href="https://x.com/EntsoftOfficial" target="_blank" rel="noopener noreferrer">
              <FaTwitter />
            </a>
          </span>
          <span className="bannerIcon">
            <a href="https://www.linkedin.com/company/entsoft" target="_blank" rel="noopener noreferrer">
              <FaLinkedinIn />
            </a>
          </span>
          <span className="bannerIcon">
            <a href="https://www.instagram.com/entsoftofficial" target="_blank" rel="noopener noreferrer">
              <FaInstagram />
            </a>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Media;
